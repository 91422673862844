module.exports = {
  development: {
    baseURL: 'https://api.ayaey.cn',
    mockURL: 'https://api.ayaey.cn'
  },
  production: {
    baseURL: 'https://api.ayaey.cn',
    mockURL: 'https://api.ayaey.cn'
  }
}
