<template>
  <div>
    <h1 class="text">
      更新日期: 2022年12月26日 生效日期: 2022年12月26日
      欢迎使用爱牙e牙，您在接受爱牙e牙提供的服务前，请仔细阅读并充分理解本协议，如果您对本协议的任何条款表示异议，您可以选择不使用爱牙e牙，您使用爱牙e牙，则意味着您将自愿遵守以下协议条款，并完全服从于爱牙e牙的统一管理。
      一、我们如何收集和使用个人信息
      在使用我们产品/服务的时候，您需要选择授权我们收集和使用个人信息的场景包含： 1.账号服务
      1.1账号登录 当您登录爱牙e牙及使用相关服务时，您可以通过爱牙e牙提供的账号，可以登录爱牙e牙。
      1.2账号信息
      登陆爱牙e牙账号后，您可根据自身需求选择填写或更改头像、昵称来完善您的信息，但如您不提供此类信息，不会影响您使用本产品和相关服务。
      2客户管理服务
      当您进行创建客户信息时，我们需要您提供真实的姓名、手机号码、微信号、性别。这些信息属于个人敏感信息，我们将采用行业内通行的方式及尽最大的商业努力来保护您个人敏感信息的安全。若您不提供这些信息，您可能无法正常使用服务。
      二、我们如何使用Cookie和同类技术
      Cookie和同类技术是互联网中的通用常用技术，是支持服务器端（或者脚本）在客户端上存储和检索信息的一种机制。当您使用多点医服务时，我们可能会使用Cookie和同类技术向您的设备发送一个或多个Cookie或匿名标识符，以收集和存储您访问、使用本产品时的信息。这么做是为了保障产品与服务的安全、高效运转，或及时发现并防范安全风险。这些技术还会帮助我们改进服务效率，提升登录和响应速度。
      我们承诺，我们不会将Cookie用于本隐私政策所述目的之外的任何其他用途。
      三、我们如何存储和保护您的个人信息 1.
      信息保存期限：在用户持续使用产品期间，我们会持续的保存用户的个人信息，当您自主删除个人信息或注销账户，我们将按照法律、法规规定的最短期限保留您的现有个人信息,在法律法规要求的最短保存期限内，我们将不再对您的个人信息进行商业化使用。当您的个人信息超出上述保存期限，我们将会对其进行去标识化处理。
      2. 信息存放的地域：我们按照法律法规规定，收集和产生的个人信息，将在中国境内存储和使用。 3.
      保障信息的安全
      我们及其关联公司采用严格的管理、技术和物理安全措施来保护您提供的信息，防止信息遭到未经授权的访问、公开披露、使用、修改、破坏或丢失；
      我们建立安全保护的管理制度、流程和组织以保障信息的安全。例如，我们严格限制访问信息的人员范围，要求他们遵守保密义务；
      在提供服务的过程中，您同意我们有权视业务开展的实际情况，向合作的第三方服务商查询您个人信息，以完成前述必要的业务流程。您本人进一步同意，在协助我们完成前述必要的业务流程时，第三方服务商有权在合理、必要范围内对我们提供的您个人信息进行保存、加工、处理关联和使用。同时，第三方服务商会采取不低于本隐私政策同等严格的隐私保护措施，且都受到严格的保密条款的约束。
      特别说明，互联网环境及任何安全系统都存在可能的未知风险，并非百分之百安全。一旦不幸发生信息安全事件，我们将按照法律法规的要求，采取合理、有效的方式及时向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。
      4.
      在您终止使用爱牙e牙服务后，我们会停止对您的信息的收集和使用，法律法规或监管部门另有规定的除外。如我们停止运营，我们将及时停止收集您个人信息的活动，将停止运营的通知以逐一送达或公告的形式通知您，并对所持有的您的个人信息进行删除或匿名化处理。
      四、您如何访问及控制您的个人信息
      我们非常重视您对个人信息的管理，并尽全力保护您对于您个人信息的访问、修改（更新或更正）、删除以及撤回授权同意的权利，以使您拥有充分的能力保障您的隐私和安全。
      1. 您有权查看您提供给我们的或由您使用爱牙e牙服务所产生的个人资料。 2.
      如果您希望删除或更正您的个人资料，请联系我们的客服人员，我们会在验证通过后的7个工作日内按照您的要求予以删除或更正。
      五、何种情况下个人信息会被共享、转让、公开披露
      1.我们将遵守相关法律法规，对您的个人信息予以保密，我们不会主动向第三方共享、转让您的个人信息。除以下情况外,我们不会向其他人共享您的个人信息:
      1.1只有共享您的个人信息，才能提供您所需要的服务。
      1.2为了提供和改进服务（其整体或相关部分），包括但不限于服务运营、维护和改进服务表现、开发新功能新特性、研究和提供客户支持等。
      1.3与您通信并使其个性化，包括以符合适用法律法规的方式向您推送最新的市场信息及优惠方案、更新您对服务请求的查询、邀请您参与调查等。
      1.4
      您通过爱牙e牙平台使用第三方服务时，会按照“个人信息的收集”条款列明的共享内容与提供服务的第三方共享您的个人信息。
      1.5
      根据法律规定，刑事侦查机关为调查犯罪，依法定程序调取的必要个人信息或行政机关、司法机构等依法定程序并经您授权同意调取的必要个人信息。
      1.6 在法律要求或允许的范围内，为了保护社会公共利益、财产或安全而提供必要的信息。 1.7
      如我们使用您的个人信息，超出了与收集时所声称的目的及具有直接或合理关联的范围，将另行获得您的明确同意或授权。
      2.非经法定程序或获得您的同意，我们不会将您的个人信息提供给任何第三方机构或个人。为了使您能够接受信息推送、在第三方平台分享信息、使用地图服务、播放视频等必要的功能用途，我们的应用中会嵌入授权合作伙伴的SDK或其他类似的应用程序（详见附件一）。对我们与之共享个人信息的公司、组织和个人，我们会与之签署严格的保密协议，要求他们按照我们的说明，本隐私政策及其他任何相关的保密和安全措施来处理个人信息。如果第三方因业务需要，确需超出前述授权范围使用个人信息的，该第三方将需再次征求您的自主选择同意。
      3.如企业发生收购、兼并、重组等变更，您的个人信息有可能因此而被转移，我们会要求变更后的主体依然遵守本隐私政策约定，履行原有责任及义务。如变更后的主体需变更个人信息使用目的，我们会要求其事先获得您的明确同意。
      六、关于通过爱牙e牙搜索/检索到的信息：
      1、爱牙e牙提供科普搜索功能。您通过爱牙e牙搜索到的任何信息，仅供您参考。 七、本政策的变更
      1.随着爱牙e牙服务的不断提升，本隐私政策的内容也可能会不时更新。但未经您明确同意，我们不会削减您依据本隐私政策所应享有的权利。我们会通过在爱牙e牙的平台/客户端产品上公布更新版本并在生效前通过平台公告或以其他适当方式提醒您相关内容的更新。我们鼓励您时常查看以了解我们最新的隐私政策。
      2.
      若您在我们公布变更后的本政策后继续使用爱牙e牙的业务和服务，则表示您已充分阅读、理解并接受该等修订后的本政策的全部内容，也将遵循该等内容使用本政策；若您不同意该等经修订后的本政策的任何内容，您即应选择停止使用爱牙e牙的产品和服务。
      八、如何联系我们
      当您有其他的投诉、建议时，您可以通过客服热线13811724851与我们联系。您也可以将您的问题发送至shenchen0614@163.com。我们将尽快核实所涉问题，并在验证您的用户身份后的7天内予以回复。
    </h1>
  </div>
</template>


<style lang="less" scoped>
.text {
  padding: 20px;
  font-size: 24px;
}
</style>