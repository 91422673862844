<template>
  <div class="home" :class="img">
    <router-link @click="jump" to="">
      <img src="@/assets/home.webp" />
    </router-link>
  </div>
</template>

<script>
import { defineComponent, onMounted } from 'vue'
import HomeObserver from './store'
import { useRouter } from 'vue-router'



export default defineComponent({
  name: 'Home',
  components: {
  },
  setup() {
    const info = HomeObserver.getInfo()

    const router = useRouter();

    onMounted(() => {
      HomeObserver.requestInfo()
    })

    function jump() {
      router.push('/register');
    }

    return {
      jump
    }
  }
})
</script>
<style>
img {
  display: block;
  width: 100%;
  height: 100%;
}
</style>