import request from '@/utils/request'
import axios from "axios";
import service from "@/utils/axios";
export type MerchantInfo = {
    merchant_name: string
    user_name: string
    phone: string
};

export type RegisterResult = {
    result: string;
};

export function toRegister(data: MerchantInfo) {

    // return new Promise((resolve, reject) => {
    //     const url = "https://api.ayaey.cn";
    //     axios.create({
    //         url,
    //         timeout: 5000
    //     }).request({
    //         url: '/api/merchant_request',
    //         method: 'post',
    //         data,
    //     }).then(
    //         res => {
    //             resolve(res.data)
    //         },
    //         err => {
    //             reject(err)
    //         }
    //     )
    // })
    return request<RegisterResult>(
        {
            url: '/api/merchant_request',
            method: 'post',
            data,
        }
    )
};
